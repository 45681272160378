<ng-container *ngIf="shippingGroup">
    <mat-accordion *ngIf="shippingGroup?.manufactureGroups" [multi]="true">
        <mat-expansion-panel *ngFor="let deliveryGroup of shippingGroup.manufactureGroups; let i = index;"
            (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>Delivery Group #{{deliveryGroup.manufactureGroupIndex}}</span>
                </mat-panel-title>
                <mat-panel-description (click)="$event.stopPropagation();">
                    <cx-schedule-replenishment-order
                        *ngIf="desiredShipmentDateAvailable && !isSpStep"
                        [deliveryGroup]="deliveryGroup"
                        [shippingGroupName]="shippingGroup.shippingGroupName"
                        [cartCode]="cartCode"
                        (dateSelected)="getSingleConsignmentInfo(deliveryGroup.manufactureGroupID)"
                    ></cx-schedule-replenishment-order>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <cx-cart-item-list
                [groups]="deliveryGroup.entryGroups"
                [readonly]="true"
                [hasHeader]="false">
            </cx-cart-item-list>
            <generac-checkout-single-consignment
                [shippingGroupName]="shippingGroup.shippingGroupName"
                [cartCode]="cartCode"
                [manufactureGroupID]="deliveryGroup?.manufactureGroupID" 
                [singleConsignmentInfo]="deliveryGroup?.singleConsignmentInfo"
                [purchaseOrderNumber]="purchaseOrderNumber"
                [updateData]="updateDataTrigger$">
            </generac-checkout-single-consignment>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
