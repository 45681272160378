/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartOutlets } from '@spartacus/cart/base/root';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CartItemListRowComponent } from './cart-item-list-row';
import { CartItemListComponent } from './cart-item-list/cart-item-list.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { AtMessageModule, IconModule, ItemCounterModule, MediaModule, OutletModule, PromotionsModule, provideOutlet, SpinnerModule } from '@spartacus/storefront';
import { CustomItemCounterModule } from 'src/app/shared/components/custom-item-counter/custom-item-counter.module';
import { NoReturnWarningModule } from '../../product/components/no-return-warning/no-return-warning.module';
import { SharedModule } from "../../../../shared/shared.module";
import { CartItemValidationWarningModule } from '../cart-item-warning/cart-item-validation-warning.module';
import { CustomCartItemComponent } from './custom-cart-item/custom-cart-item.component';
import { CustomSubscriptionsTableComponent } from './custom-susbscriptions-table/custom-subscriptions-table.component';
import { GeneracSpFlammableComponent } from 'src/app/shared/components/generac-sp-flammable/generac-sp-flammable.component';
import { GeneracSpLtlComponent } from 'src/app/shared/components/generac-sp-ltl/generac-sp-ltl.component';

@NgModule({
  imports: [
    AtMessageModule,
    CartCouponModule,
    CartItemValidationWarningModule,
    CommonModule,
    FeaturesConfigModule,
    I18nModule,
    IconModule,
    ItemCounterModule,
    MediaModule,
    OutletModule,
    CustomItemCounterModule,
    PromotionsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    AddToCartModule,
    SpinnerModule,
    SharedModule,
    NoReturnWarningModule,
    GeneracSpLtlComponent,
    GeneracSpFlammableComponent,
  ],
  providers: [
    provideOutlet({
      id: CartOutlets.CART_ITEM_LIST,
      component: CartItemListComponent,
    })
  ],
  declarations: [
    CartItemComponent,
    CartItemListComponent,
    CartItemListRowComponent,
    CustomCartItemComponent,
    CustomSubscriptionsTableComponent,
  ],
  exports: [
    CartItemComponent,
    CartItemListRowComponent,
    CartItemListComponent,
    CustomCartItemComponent,
    CustomSubscriptionsTableComponent,
  ],
})
export class CartSharedModule {}
