import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NavigationCancel, Event, NavigationEnd, Router } from "@angular/router";
import { SubscriptionsService } from "../../../../../services/subscriptions.service";
import { CheckoutStepService } from "@spartacus/checkout/base/components";
import { filter, map } from "rxjs/operators";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { UserGroup } from "../../../../../enums/user.enum";
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Component({
  selector: 'cx-cart-proceed-to-checkout',
  templateUrl: './cart-proceed-to-checkout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartProceedToCheckoutComponent implements OnInit, OnDestroy {
  cartValidationInProgress = false;
  isButtonHidden$!: Observable<boolean>;
  protected subscription = new Subscription();

  constructor(
    router: Router,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    cd?: ChangeDetectorRef
  );
  /**
   * @deprecated since 5.2
   */
  constructor(router: Router);
  constructor(
    protected router: Router,
    protected cd?: ChangeDetectorRef,
    protected subscriptionsService?: SubscriptionsService,
    protected checkoutStepService?: CheckoutStepService,
    protected activeCartFacade?: ActiveCartFacade,
    protected userAccount?: UserAccountFacade,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.getUserAccount();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  disableButtonWhileNavigation(): void {
    this.cartValidationInProgress = true;
    this.checkoutStepService.resetSteps();
  }

  private subscribeToRouterEvents() {
    this.subscription.add(
      this.router.events
        .pipe(filter((event: Event) => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(() => {
          this.cartValidationInProgress = false;
          this.cd?.markForCheck();
        })
    );
  }

  private getUserAccount(): void {
    this.isButtonHidden$ = this.userAccount.get()
      .pipe(
        filter(user => !!user),
        map(user => !user?.roles?.includes(UserGroup.restrictedCheckoutGroup))
      );
  }
}
