<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.ITEM">
  <ng-template [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"></ng-template>
  <cx-cart-item-validation-warning
    [code]="item.product?.code"
  ></cx-cart-item-validation-warning>
  <div [ngClass]="compact ? 'cx-compact row' : 'row'">
    <!-- Item Image -->
    <div class="col-2 cx-image-container">
      <a
        [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
        tabindex="0"
      >
        <cx-media
          [container]="imageContainer"
          format="cartIcon"
        ></cx-media>
      </a>
    </div>
    <!-- Item Information -->
    <div class="cx-info col-10">
      <div class="cx-info-container row">
        <!-- Item Description -->
        <div [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-5'">
          <div *ngIf="item.product?.name" class="cx-name">
            <a
              class="cx-link"
              [routerLink]="
                { cxRoute: 'product', params: item.product } | cxUrl
              "
              ><h3>{{ item.product?.name }}</h3></a
            >
          </div>
          <div *ngIf="item.product?.code && !item.product?.displayModelNumber" class="cx-code">
            {{ (item.product?.servicePart ?  'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.code }}
          </div>
          <div *ngIf="item.product?.displayModelNumber" class="cx-code">
            {{ (item.product?.servicePart ?  'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.displayModelNumber }}
          </div>

          <!-- Item Details Outlet -->
          <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"> </ng-template>

          <!-- Variants -->
          <ng-container *ngIf="item.product?.baseOptions?.length">
            <div
              *ngFor="
                let variant of item.product?.baseOptions[0]?.selected
                  ?.variantOptionQualifiers
              "
              class="cx-property"
            >
              <div class="cx-label" *ngIf="variant.name && variant.value">
                {{ variant.name }}: {{ variant.value }}
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Item Price -->
        <div
          *ngIf="item.basePrice"
          class="cx-price"
          [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-2'"
        >
          <div
            class="cx-label"
            [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'"
          >
            {{ 'cartItems.itemPrice' | cxTranslate }}
          </div>
          <div *ngIf="item.basePrice" class="cx-value">
            {{ item.basePrice?.formattedValue }}
          </div>
        </div>
        <!-- Item Quantity -->
        <div class="cx-quantity" [ngClass]="compact ? '' : ' col-3'">
          <div
            class="cx-label"
            [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'"
            placement="left"
            title="{{ 'cartItems.quantityTitle' | cxTranslate }}"
          >
            {{ 'cartItems.quantity' | cxTranslate }}
          </div>
          <div
                  *ngIf="quantityControl"
                  class="cx-value"
                  [class.readonly-value]="readonly"
          >
            <cx-custom-item-counter
                [control]="quantityControl"
                [readonly]="!item.updateable || !!readonly || !!options.isSaveForLater"
                [min]="+item.product?.minOrderQuantity"
                [step]="+item.product?.addToCartQtyMultiplier"
                [max]="+item.product?.maxOrderQuantity"
                messagePosition="position-right"
                [allowZero]="true"
            >
            </cx-custom-item-counter>
          </div>
        </div>
        <!-- Total -->
        <ng-container *ngIf="options.isSaveForLater; else total">
          <div
            class="cx-total"
            [ngClass]="compact ? '' : ' col-md-3 col-lg-3 col-xl-2'"
          >
            <div
              class="cx-label"
              [ngClass]="
                compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'
              "
            >
              {{ 'saveForLaterItems.stock' | cxTranslate }}
            </div>
            <div
              *ngIf="item.product?.stock.stockLevel >= 0; else forceInstock"
              class="cx-value"
            >
              {{ item.product?.stock?.stockLevel }}
            </div>
            <ng-template #forceInstock>
              <div class="cx-value">
                {{ 'saveForLaterItems.forceInStock' | cxTranslate }}
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <!-- Availability -->
      <div
        *ngIf="isProductOutOfStock(item.product)"
        class="cx-availability col-12"
      >
        {{ 'addToCart.outOfStock' | cxTranslate }}
      </div>

      <!-- Item Bundle Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"> </ng-template>

      <!-- Promotion -->

      <cx-promotions [promotions]="item.promotions"></cx-promotions>

      <!-- Actions -->
      <div
        *ngIf="(!readonly || options.isSaveForLater) && item.updateable"
        class="cx-actions col-12"
      >
        <ng-container *ngIf="!isProductOutOfStock(item.product)">
          <ng-container
            *ngTemplateOutlet="
              options.optionalBtn;
              context: {
                $implicit: { loading: quantityControl.disabled, item: item }
              }
            "
          ></ng-container>
        </ng-container>

        <div class="col-md-3 cx-remove-btn">
          <button
            (click)="removeItem()"
            [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
            [disabled]="quantityControl.disabled"
            attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
            class="link cx-action-link"
          >
            <cx-icon [type]="iconTypes.TRASH"></cx-icon>
            {{ 'common.remove' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #total>
    <div
      *ngIf="item?.basePrice && item.quantity && item.totalPrice"
      class="cx-total"
      [ngClass]="compact ? '' : ' col-md-3 col-xl-2'"
    >
      <div
        class="cx-label"
        [ngClass]="compact ? '' : ' d-block d-md-none d-lg-none d-xl-none'"
      >
        {{ 'cartItems.total' | cxTranslate }}
      </div>
      <div class="cx-value" *ngIf="item.basePrice?.value && item.totalPrice?.currencyIso">
        {{ (item.basePrice?.value * item.quantity) | currency: item.totalPrice.currencyIso }}
      </div>
    </div>
  </ng-template>

</ng-template>
