<ng-container *ngIf="shippingGroup$ | async as shippingGroup">
    <generac-checkout-delivery-groups [shippingGroup]="shippingGroup" [cartCode]="cartCode" [purchaseOrderNumber]="purchaseOrderNumber">
    </generac-checkout-delivery-groups>
</ng-container>

<div class="cx-checkout-btns row">
    <div class="col-md-6 col-lg-6">
        <button class="cx-btn btn btn-block btn-secondary" (click)="back()">
            {{ backBtnText | cxTranslate }}
        </button>
    </div>
    <div class="col-md-6 col-lg-6">
        <button class="cx-btn btn btn-block btn-primary" [disabled]="false" (click)="next()">
            {{ 'common.continue' | cxTranslate }}
        </button>
    </div>
</div>