
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsBreadcrumbsComponent, PageMetaService, ProductSearchService, TranslationService } from '@spartacus/core';
import { BreadcrumbComponent, CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'cx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneracBreadcrumbComponent extends BreadcrumbComponent implements OnInit {
  protected modelNumber$: Observable<string | null>;

  constructor(
    public override component: CmsComponentData<CmsBreadcrumbsComponent>,
    protected override pageMetaService: PageMetaService,
    protected productSearchService: ProductSearchService,
    private translationService: TranslationService
  ) {
    super(component, pageMetaService, translationService);
  }

  private setModelNumber(): void {
    this.modelNumber$ = this.productSearchService
    .getResults()
    .pipe(
      map((results: any) => results.modelNumber),
      switchMap((modelNumber) =>
        modelNumber ? this.translationService
          .translate('pageMetaResolver.search.modelNumber', {
            modelNumber
          }) : of(null)
      )
    );
  
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.setCrumbsVal();
    this.setModelNumber();
  }

  private setCrumbsVal(): void {
    this.crumbs$ = combineLatest([
      this.pageMetaService.getMeta(),
      this.translationService.translate('common.home'),
    ]).pipe(
      map(([meta, textHome]) =>
        meta?.breadcrumbs ? meta.breadcrumbs : [{ label: textHome, link: '/' }]
      )
    );
  }
}
