<ng-container *ngIf="product$ | async as product">
  <div class="code" *ngIf="!product.displayModelNumber">
    {{ (product?.servicePart ?  'productSummary.part' : 'productSummary.id') | cxTranslate }} #{{ product?.code }}
  </div>
  <div class="code" *ngIf="product.displayModelNumber">
    {{ (product?.servicePart ?  'productSummary.part' : 'productSummary.id') | cxTranslate }} #{{ product?.displayModelNumber }}
  </div>
  <div class="name" *ngIf="product.name">
    {{ product?.name }}
  </div>
  <add-to-favorites
    [product]="product"
    [class.border-grey]="product.erpStatus !== 'DISCONTINUED'"
  ></add-to-favorites>
</ng-container>
