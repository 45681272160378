<div class="customcartitem">
    <div class="customcartitem-image-block">
        <cx-media
                [container]="imageContainer"
                format="product"
        ></cx-media>
    </div>

    <div class="customcartitem-block">
        <div class="customcartitem-block-item">
            <div *ngIf="item.product?.code && !item.product?.displayModelNumber"
                 class="customcartitem-block-item-model">
                {{ (item.product?.servicePart ?  'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.code }}
            </div>
            <div *ngIf="item.product?.displayModelNumber" class="customcartitem-block-item-model">
                {{ (item.product?.servicePart ?  'cartItems.part' : 'cartItems.id') | cxTranslate }} #{{ item.product?.displayModelNumber }}
            </div>
            <div class="customcartitem-block-item-name">{{item.product?.name}}</div>
        </div>
        <div class="customcartitem-block-price">
            <div class="customcartitem-block-price-title">{{ 'cartItems.itemPrice' | cxTranslate }}</div>
            <div class="customcartitem-block-price-value">{{ item.basePrice?.formattedValue }}</div>
        </div>
        <div
                *ngIf="quantityControl"
                [class.readonly-value]="readonly"
                class="customcartitem-block-qty"
        >
            <cx-custom-item-counter
                    [control]="quantityControl"
                    [readonly]="!item.updateable || !!readonly || !!options.isSaveForLater"
                    [min]="+item.product?.minOrderQuantity"
                    [step]="+item.product?.addToCartQtyMultiplier"
                    [max]="+item.product?.maxOrderQuantity"
                    messagePosition="position-right"
                    [allowZero]="true"
            ></cx-custom-item-counter>
        </div>

        <div class="customcartitem-block-total">
            <div class="customcartitem-block-total-title">
                {{ 'cartItems.total' | cxTranslate }}
            </div>
            <div *ngIf="item.basePrice?.value && item.totalPrice?.currencyIso" class="customcartitem-block-total-value">
                {{ (item.basePrice?.value * item.quantity) | currency: item.totalPrice.currencyIso }}
            </div>
        </div>
        <div class="customcartitem-block-actions">
            <button
                    (click)="removeItem()"
                    [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
                    [disabled]="quantityControl.disabled"
                    [attr.aria-label]="'addToCart.removeFromCart' | cxTranslate"
                    class="link cx-action-link"
            >
                <cx-icon [type]="iconTypes.TRASH"></cx-icon>
            </button>
        </div>
    </div>
</div>