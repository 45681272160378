import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ShippingGroup } from 'src/app/interfaces/cart';
import { CommonModule } from '@angular/common';
import { InfoMessageModule } from 'src/app/shared/components/info-message/info-message.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CheckoutScheduleReplenishmentOrderModule } from '../checkout-schedule-replenishment-order/checkout-schedule-replenishment-order.module';
import { CartSharedModule } from '../../../cart/cart-shared';
import { BehaviorSubject } from 'rxjs';
import { CheckoutSingleConsignmentComponent } from '../checkout-single-consignment/checkout-single-consignment.component';
import { CartType } from 'src/app/enums/cart-type.enum';

@Component({
  selector: 'generac-checkout-delivery-groups',
  templateUrl: './checkout-delivery-groups.component.html',
  styleUrls: ['./checkout-delivery-groups.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InfoMessageModule,
    MatExpansionModule,
    CartSharedModule,
    CheckoutScheduleReplenishmentOrderModule,
    CheckoutSingleConsignmentComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutDeliveryGroupsComponent implements OnInit {
  private updateDataSubject$$ = new BehaviorSubject<string | null>(null);

  @Input() shippingGroup: ShippingGroup;
  @Input() cartCode: string;
  @Input() purchaseOrderNumber: string;
  desiredShipmentDateAvailable: boolean = JSON.parse(localStorage.getItem('desiredShipmentDateAvailable'));
  panelOpenState: boolean[] = [];
  updateDataTrigger$ = this.updateDataSubject$$.asObservable();
  isSpStep: boolean;

  getSingleConsignmentInfo(manufactureGroupId: string): void {
    this.updateDataSubject$$.next(manufactureGroupId);
  }

  ngOnInit() {
    this.isSpStep = this.shippingGroup?.cartTypes[0] == CartType.SERVICE_PARTS;
  }
}
