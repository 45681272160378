import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SingleConsignmentInfo } from 'src/app/interfaces/cart';
import { ICON_TYPE, IconModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UserAccountService } from '@spartacus/user/account/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'generac-checkout-single-consignment',
  templateUrl: './checkout-single-consignment.component.html',
  styleUrls: ['./checkout-single-consignment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    FormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutSingleConsignmentComponent implements OnInit, OnDestroy {
  @Input() shippingGroupName: string;
  @Input() cartCode: string;
  @Input() manufactureGroupID: string;
  @Input() singleConsignmentInfo: SingleConsignmentInfo;
  @Input() updateData: Observable<string>;
  @Input() purchaseOrderNumber: string;

  iconTypes = ICON_TYPE;
  singleConsignmentInfoState: boolean;

  private destroy$ = new Subject();

  constructor(
    private checkoutService: CheckoutService,
    private userAccountService: UserAccountService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.singleConsignmentInfoState = this.singleConsignmentInfo?.state;
    this.updateData
      .pipe(takeUntil(this.destroy$))
      .subscribe(manufactureGroupID => manufactureGroupID == this.manufactureGroupID && this.getSingleConsignmentInfoByManufactureGroup(manufactureGroupID));
  }

  getSingleConsignmentInfoByManufactureGroup(manufactureGroupId: string): void {
    this.userAccountService.get().pipe(
      takeUntil(this.destroy$),
      switchMap((user) => this.checkoutService.getCheckoutInfo(
        this.cartCode, user?.uid, this.shippingGroupName, manufactureGroupId
      )),
      tap(info => {
        this.singleConsignmentInfo = info.singleConsignmentInfo;
        this.singleConsignmentInfoState = this.singleConsignmentInfo?.state;
        this.cdr.markForCheck();
      })
    ).subscribe();
  }

  setSingleConsignmentState(val: any): void {
    const body = {
      purchaseOrderNumber: this.purchaseOrderNumber,
      singleConsignmentInfo: {
        state: val?.target?.checked
      }
    };
    this.userAccountService.get().pipe(
      takeUntil(this.destroy$),
      switchMap((user) => this.checkoutService.setCheckoutInformation(
        body,
        user?.uid,
        this.cartCode,
        this.shippingGroupName,
        this.manufactureGroupID
      ))
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
