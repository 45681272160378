import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { GeneracActiveCartService } from '../../../cart/core/facade/active-cart.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ShippingGroup } from 'src/app/interfaces/cart';
import { CartType } from 'src/app/enums/cart-type.enum';

@Component({
  selector: 'cx-checkout-finished-goods',
  templateUrl: './checkout-finished-goods.component.html',
  styleUrls: ['./checkout-finished-goods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutFinishedGoodsComponent implements OnInit, OnDestroy {
  cartCode: string;
  purchaseOrderNumber: string;
  shippingGroup$: Observable<ShippingGroup> = this.activeCartFacade.getActive().pipe(
    tap(cart => this.checkoutService.disableCheckoutSteps(cart?.cartTypes)),
    tap(cart => this.cartCode = cart.code),
    tap(cart => this.purchaseOrderNumber = cart.purchaseOrderNumber),
    switchMap(cart => of(cart.shippingGroups) as Observable<ShippingGroup[]>),
    map((shippingGroups: ShippingGroup[]) => shippingGroups.filter(sg => sg.cartTypes[0] === CartType.FINISHED_GOODS.toString())),
    switchMap(shippingGroups => of(shippingGroups[0])),
  )

  get backBtnText(): string {
    return this.checkoutStepService.getBackBntText(this.activatedRoute);
  }

  constructor(
    protected checkoutStepService: CheckoutStepService,
    protected activatedRoute: ActivatedRoute,
    protected activeCartFacade: GeneracActiveCartService,
    private checkoutService: CheckoutService,
  ) {
  }

  ngOnInit(): void {

  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  next(): void {
    this.checkoutStepService.next(this.activatedRoute);
  }

  ngOnDestroy(): void {

  }
}
